import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
// import cn from 'classnames'
import { Link } from 'gatsby'
import SEO from 'components/seo'

export default withMainLayout(DonatePage)

function DonatePage() {
  return(
    <div className='container'>
      <SEO
        title='Спасибо!'
      />
      <div className={css.donatePage}>
        <h1
          align='center'
          style={{margin: '60px 0 25px'}}
        >
          Спасибо!
        </h1>
        <p
          className={css.donateText}
        >
          Текст с благодарностью.
        </p>
        <div className={css.donateButtons}>
          <Link to='/' className='button label'>Вернуться на главную</Link>
        </div>
      </div>
    </div>
  );
}